import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Cart.css';
import Popup from '../popup/popup.jsx';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '../Spinner/Spinner';

export default function Cart(props) {
    const [books, setBooks] = useState(props.cart ?? []);
    const [order, setOrder] = useState({});
    const [showorder, setShoworder] = useState(false);
    const [address, setAddress] = useState({
        recipient_name: '',
        line1: '',
        phone: '',
        city: '',
        state: '',
        postal_code: '',
        email: '',
    });
    const [touched, setTouched] = useState({
        recipient_name: false,
        line1: false,
        city: false,
        phone: false,
        state: false,
        postal_code: false,
        email: false,
    });
    const [errors, setErrors] = useState({
        recipient_name: true,
        line1: true,
        city: true,
        phone: false,
        state: true,
        postal_code: true,
        email: true
    });
    const [showpopup, setShowpopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [useMnTax, setUseMTax] = useState(true);
    const [isFormValid, setIsFormVaild] = useState(false);
    const [recaptchaToken, setRecaptchToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [orderReady, setOrderReady] = useState(false);

    const navigate = useNavigate();

    const cartIsEmpty = () => (books === undefined || books.length === 0);

    const hidePopup = () => {
        setShowpopup(false);
    }

    const showSuccessMessage = () => {
        const booksToRemove = [...books];
        booksToRemove.forEach((b) => changeCart(true, b));
        setShoworder(false);

        navigate("/success");
    }

    const showPopupMessage = (message) => {
        setShowpopup(true);
        setPopupMessage(message);
    }

    useEffect(() => {
        setBooks(books)
    }, [books])

    useEffect(() => {
        const isEmailValid = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(address.email));
        
        setErrors({
            recipient_name: address.recipient_name === '',
            line1:address.line1 === '',
            city:address.city === '',
            state:address.state === '',
            postal_code:address.postal_code === '',
            email: !isEmailValid,
        });
    }, [address]);

    useEffect(() => {
        setIsFormVaild(!Object.keys(errors).some(x => errors[x]));
    }, [errors]);

    useEffect(() => {
        if(order.submit === true) {
            submitOrder(order);
        }
    // eslint-disable-next-line
    }, [order]);

    const changeCart = (removeFlag, book) => {
        props.changeCart(removeFlag, book);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setAddress({
            ...address,
            [name]:value});
    }

    const handleBlur = (event) => {
        const name = event.target.name;

        setTouched({
            ...touched,
            [name]: true,
        });
    }

    const shouldMarkError = (field) => {
        const hasError = errors[field];
        const isTouched = touched[field];
        return isTouched && hasError;
    }

    const validateRecaptcha = () => {
        return true && recaptchaToken;
    }

    const recaptchaOnChange = (value) => {
        setRecaptchToken(value);
    }

    const toCurrency = (num) => {
        return '$' + Number.parseFloat(num).toFixed(2)
    }

    const getOrder = () => {
        setLoading(true);

        //call the server with all the data and get a total
        axios.post("/.netlify/functions/getorder", { books: books })
        .then(response => {
            setOrder(response.data.order);
            setShoworder(true);
        }).catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
    }

    const orderwithother = () => {
        if(!isFormValid) {
            showPopupMessage('Please complete all required fields');
            return;
        }

        setOrder({
            ...order,
            paymentType: "Pay by Check (payment pending)",
        })
        setOrderReady(true);
    }

    const authorizeOrderwithpaypal = (authorization, data) => {
        setOrder({
            ...order,
            paymentType: "Pay by Paypal (authorized)",
            authorizationID: authorization.purchase_units[0].payments.authorizations[0].id,
            paypalOrderID: data.orderID,
            submit: true,
        });

        setOrderReady(true);
    }

    const checkOut = () => {
        if(validateRecaptcha()) {
            setOrder({...order, submit: true});
        } else {
            showPopupMessage('Please complete reCAPTCHA');
        }
    }

    const submitOrder = (order) => {
        setLoading(true);
        var myOrder = order;
        myOrder.recaptchaToken = recaptchaToken;
        myOrder.totalTaxed = myOrder.subtotal + myOrder.tax + myOrder.shippingcost;
        //myOrder.totalTaxed = Math.round((myOrder.totalTaxed + Number.EPSILON) * 100) / 100;
        myOrder.totalUntaxed = myOrder.subtotal + myOrder.shippingcost;
        myOrder.shippingAddress = address;
        axios.post('/.netlify/functions/placeorder', {
            body: {order: order},
        }).then(response => {
            showSuccessMessage();
        }).catch(error => {
            console.log(error);
            if(window.grecaptcha) window.grecaptcha.reset();
            showPopupMessage(`ERROR ${error.response.status}:\n${error.response.data}`);
        }).finally(() => {
            setOrder({...order, submit: false});
            setLoading(false);
        })
    }

    return <div className="order">
        {showpopup && <Popup handleClose={hidePopup}><pre>{popupMessage}</pre></Popup> }
        {cartIsEmpty() && <h3 className="cart">Your Shopping cart is empty</h3>}
        
        <div className="cart">
            {!cartIsEmpty() && <div>
                <Spinner loading={loading} ><button onClick={getOrder}>{showorder ? "Update Cart" : "Check out"}</button></Spinner>
            </div>}
            {books.map((book, index) => (
                <div key={index} className={index % 2 === 0 ? "even" : "odd"}>
                    <button onClick={() => {changeCart(true, book); getOrder()}}>Remove from Cart</button>
                    <div>
                        <span className="author">{book.author.replace( /(<([^>]+)>)/ig, '')} </span>
                        <span className="title">{book.title.replace( /(<([^>]+)>)/ig, '')} </span> 
                    </div>
                    <div><span className="price">{toCurrency(book.price)}</span></div>
                </div>
            ))}
            
        </div>
        {showorder && <div className="confirm">
            <div><div>Subtotal:</div><div>{toCurrency(order.subtotal)}</div></div>
            <div><div>Shipping:</div><div>{toCurrency(order.shippingcost)}</div></div>
            <div><div>MN Tax:</div><div>{toCurrency(order.tax)}</div></div>
            <div>Include MN tax: (MN Residents Only)<input type='checkbox' checked={useMnTax} onChange={(e) => {setUseMTax(e.target.checked)}} /></div>
            <div><div>Total:</div><div>{toCurrency(order.subtotal + order.shippingcost + (useMnTax ? order.tax : 0))}</div></div>
        </div>}
        {showorder && <form className="address">
            <input value={address.recipient_name}  name="recipient_name"   onChange={handleChange} type="name"    className={`full  ${shouldMarkError('recipient_name') ? "error" : ""}`}onBlur={handleBlur} placeholder="Name" autoComplete="on" />
            <input value={address.phone}           name="phone"            onChange={handleChange} type="tel"     className={`half  ${shouldMarkError('phone') ? "error" : ""}`}         onBlur={handleBlur} placeholder="Phone Number" autoComplete="on" />
            <input value={address.email}           name="email"            onChange={handleChange} type="email"   className={`half  ${shouldMarkError('email') ? "error" : ""}`}         onBlur={handleBlur} placeholder="E-Mail Address" id='email' autoComplete="on" />
            <input value={address.line1}           name="line1"            onChange={handleChange} type="street"  className={`full  ${shouldMarkError('line1') ? "error" : ""}`}         onBlur={handleBlur} placeholder="Street" autoComplete="on" />
            <input value={address.city}            name="city"             onChange={handleChange} type="city"    className={`third ${shouldMarkError('city') ? "error" : ""}`}          onBlur={handleBlur} placeholder="City" autoComplete="on" />
            <input value={address.state}           name="state"            onChange={handleChange} type="state"   className={`third ${shouldMarkError('state') ? "error" : ""}`}         onBlur={handleBlur} placeholder="State" autoComplete="on" />
            <input value={address.postal_code}     name="postal_code"      onChange={handleChange} type="zip"     className={`third ${shouldMarkError('postal_code') ? "error" : ""}`}   onBlur={handleBlur} placeholder="Zip" autoComplete="on" />
        </form>}
        {showorder && <div className="message">
            <textarea value={address.message || ''}      name="message"          onChange={handleChange} placeholder="Message for Jan" />
        </div>}
        {showorder && <div className={`buttons ${loading ? 'hidden' : ''}`}>
            
            <PayPalScriptProvider options={{ "client-id": order.clientId, intent: "authorize" }}>
                {!orderReady && <span className="small">Use Paypal to pay with a credit card.</span>}
                {!orderReady && <span id="paypalbutton">
                <PayPalButtons style={{height: 50, layout: 'horizontal', color: 'blue', shape: 'rect', tagline: 'false'}}
                    shippingPreference='NO_SHIPPING'
                    disabled={!isFormValid}
                    forceReRender={[useMnTax, address, order]}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: +(order.subtotal + order.shippingcost + (useMnTax ? order.tax : 0)).toFixed(2),
                                    }
                                }
                            ]
                        });
                    }}
                    onApprove={(data, actions) => {
                        actions.order.authorize().then(function(authorization) {
                            return authorizeOrderwithpaypal(authorization, data);
                        });
                    }}
                /></span>}
            </PayPalScriptProvider>
            {!orderReady && <button onClick={orderwithother} disabled={!isFormValid}>Pay by Check</button>}
            {orderReady && <span>Pay By Check Selected.</span>}
            {orderReady && <ReCAPTCHA onChange={recaptchaOnChange} sitekey={order.recaptchaKey} />}
            {orderReady && <button onClick={checkOut}>Place Your Order</button>}
            
            
        </div>}
    </div>
}