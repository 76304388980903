import React from "react";

export default function DownloadCatalog(props) {

    const dateCode = () => {
        const today = new Date();
        const shortMonth = today.toLocaleString('en-us', { month: 'short' }); /* Jun */
        const year = today.getFullYear().toString().substr(-2)
        return year + shortMonth.toUpperCase();
    }

    return <div>
        <a href="../.netlify/functions/downloadCatalog" download={`${dateCode()}_BiblioCatalog.txt`} >Download Catalog file for Biblio.com</a>
    </div>
}


