import React from "react";
import './Cart.css';


export default function Success(props) {

    return (
    <div className="order">
        <div className="cart">
            <h2>Success! </h2>
            <h3>Thank you for your order.</h3>
            <h4>You should receive a confirmation E-mail soon.</h4>
        </div>
    </div>);
}