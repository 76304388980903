import React from 'react';
import logo from '../images/booksyes.jpg';
import corner from '../images/corner.png'
import Search from '../Search/Search';
import { Outlet, useNavigate } from 'react-router-dom';

export default function Main(props) {
    const navigate = useNavigate();
    return <div className="App">
        <div className="sticky">
            <img onClick={() => navigate(`/`)} src={logo} alt="Logo" id="logo" />
            <img src={corner} className="bg topleft"  alt=""/>
            <img src={corner} className="bg topright"  alt=""/>
            <div className="topname">Jan Wright</div>
            <div className="topaddr">17105 Nowthen Blvd., Anoka, MN 55303 <br/> 763-753-3429 </div>
            <hr />
            <div className="toptext">Used, collectible, and out-of-print books -- good books -- for children and young people of all ages!</div>
        </div>
        <div className="header sticky top">
            <Search cartcount={props.cartcount} />
        </div>
        <Outlet />
        <div className="footer sticky">
            {/* eslint-disable-next-line */}
            <a href="mailto:bigt40@aol.com">Email Jan</a> | <a href="mailto:jdhenckel@gmail.com">Email Webmaster</a> | <a href="javascript:" onClick={() => navigate(`/`)}>About Us</a>
            <img src={corner} className="bg bottomleft"  alt=""/>
            <img src={corner} className="bg bottomright"  alt=""/>
        </div>
    </div>
}