import React from "react";
import "./BookEntry.css";
import newImage from "../images/new.gif";
import noImage from "../images/noImage.png";

function BookEntry(props) {
    const toCurrency = (num) => {
        return '$' + Number.parseFloat(num).toFixed(2)
    }

    const imageError = (e) => {
        if(props.showImageErrors) {
            e.target.src = noImage;
        } else {
            e.target.style.display = 'none';
        }

        e.target.onError = null; //prevent a loop
    }

    return (
        <div className="bookentry">
            <div>
                <a href={'https://covers.openlibrary.org/b/isbn/' + props.book.ISBN + '-L.jpg?default=false'} target="_blank" rel="noopener noreferrer">
                    <img className="bookImage" onError={(e) => {e.target.style.display = 'none'; e.target.onError = null;}} src={'https://covers.openlibrary.org/b/isbn/' + props.book.ISBN + '-L.jpg?default=false'} alt=""/>
                </a>
            </div>
            <div className="main">
                {props.book.isNew === "true" && <img id="newImage" src={newImage} alt="new!"/>}  
                <span className="author" dangerouslySetInnerHTML={{__html: props.book.author}} /> &nbsp;
                <span className="title" dangerouslySetInnerHTML={{__html: props.book.title}} /> &nbsp;
                <span className="descr" dangerouslySetInnerHTML={{__html: props.book.description}} /> &nbsp;  
                <span className="price"> {toCurrency(props.book.price)} </span>
            </div>
            {(props.book.imageURLS.length !== 0) && <div>
                {props.book.imageURLS.map((image, index) => (
                    <a key={index} href={image} target="_blank" rel="noopener noreferrer"><img className="bookImage" onError={imageError} src={image} border="0" alt=""/></a>
                ))}
            </div>}
        </div>
    );
}

export default BookEntry;