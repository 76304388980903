import axios from "axios";
import React, {useEffect, useState} from "react";
import "./AboutUs.css";

export default function AboutUs(props) {

    const [shippingRates, setShippingRates] = useState({});

    useEffect(() => {
        axios.get('/.netlify/functions/getShippingRates').then((response) => {
            setShippingRates(response.data);
        });
    }, []);

    return <div className="aboutus">
        <h3>Hello and welcome to Books of Yesterday,</h3>
        <p />
        I love to find really good books, books that support scriptural principles
        and are well written.
        Since so many good books are out-of-print, I'm often going to auctions
        or searching the internet, and I visit
        used book stores wherever I travel around the country.
        <p />
        I'm sure you appreciate the value of a good book, especially for
        raising your children.  The books I have listed in my catalog are selected
        specifically with home-educating families in mind.
        However, they are for anyone who is interested in finding and preserving good
        books for their children and grandchildren.
        Incidentally, that is just how I got started:  finding good out-of-print
        books to have for my own children and grandchildren.
        <p />
        Many of the titles in my catalog are taken from recommended reading lists
        of various home-school enthusiasts.  Many of them I have read and
        thoroughly enjoyed, but I certainly have not read all of them.  I firmly
        believe that a good children's book is even better when read as an
        adult.
        <p />
        In any case, I hope you will browse and enjoy.  And if you purchase a book you
        don't care for, I will be happy to take it back.
        <p />
        Thanks,  Jan

        <h2>Shipping Information</h2>

        <b>Domestic</b>: ${shippingRates?.shippingcost} for the first book, ${shippingRates?.additionalshippingcost} each additional book.  Orders over ${shippingRates?.freeshippingafter} get free shipping.
        <p />
        <b>International</b>: I can ship to many other countries.  Send me a note to arrange shipping before ordering.
    </div>
}