import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import BookEntry from "./BookEntry";
import CartButton from "./CartButton";
import "./Catalog.css";

export default function Catalog(props) {
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useState([]);
    const [nextCategory, setNextCategory] = useState('');
    const [cart] = useState(props.cart ?? []);
    const [showImageErrors, setShowImageErrors] = useState(false);
    const {type, query, scope} = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        var url = '';
        let encodeQuery = '';
        if (query != null && query !== '*') 
            encodeQuery = encodeURIComponent(query);

        switch(type) {
            case 'category':
                url = `/.netlify/functions/bycategory?query=${encodeQuery}`
                break;
            case 'recent':
                url = '/.netlify/functions/new';
                break;
            case 'photos':
                setShowImageErrors(true);
                url = '/.netlify/functions/hasphoto';
                break;
            case 'search':
                url = `/.netlify/functions/search?query=${encodeQuery}&scope=${scope}`
                break;
            default:
                return;
        }
        setLoading(true);
        
        axios.get(url)
        .then(res => {
            handleResponse(res);
        }).finally(() => {
            setLoading(false);
        });
    }, [type, query, scope]);

    const handleResponse = (res) => {
        setBooks(res.data.books);
        setNextCategory(res.data.nextCategory);
    }

    const changeCart = (removeFlag, book) => {
        props.changeCart(removeFlag, book);
    }

    const inCart = (book) => {
        return cart.some(e => e.id === book.id);
    }

    const gotoNext = () => {
        if (nextCategory) navigate(`../../catalog/category/${encodeURIComponent(nextCategory)}`)
    }

    return(
        <div className="catalog">
            <Spinner loading={loading} >
                {(books === undefined || books.length === 0) && <h3>This query returned no results.</h3>}
                
                {books.map((b, index) => (
                    <div key={index} className={index % 2 === 0 ? "bookContainer even" : "bookContainer odd"}>
                        <CartButton inCart={inCart(b)} clickCallback={(action) => changeCart(action, b)} />
                        <BookEntry book={b} showImageErrors={showImageErrors}></BookEntry>
                    </div>
                ))}
                { nextCategory != null && nextCategory.length > 3 && (
                       <div className="nextCategory">
                       Next Category: <button onClick={gotoNext}>{nextCategory}</button>
                       </div>
                )}
            </Spinner>
        </div>
    );
}