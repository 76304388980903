import React, { useState } from 'react';
import axios from 'axios';
import Popup from '../popup/popup.jsx';
import './ViewLogs.css';
import Spinner from '../Spinner/Spinner.jsx';


export default function ViewLogs(props) {
    const [password, setPassword] = useState('');
    const [logs, setLogs] = useState([]);
    const [showpopup, setShowpopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        if(e.target.name === 'password') setPassword(e.target.value);
    };

    const getLogs = () => {
        setLoading(true);
        axios.post(`/.netlify/functions/viewlogs`, { pass: password })
            .then(res => {
                setLogs(res.data);
            }).catch(error => {
                showPopupMessage(`ERROR ${error.response.status}:\n${error.response.data}`);
                setPassword('');
            }).finally(() => setLoading(false))
    }

    const showPopupMessage = (message) => {
        setShowpopup(true);
        setPopupMessage(message);
    }

    const hidePopup = () => {
        setShowpopup(false);
    }

    return <div className='ViewLogs'>
        {showpopup && <Popup handleClose={hidePopup}><pre>{popupMessage}</pre></Popup>}
        <div>
            <input onChange={handleChange} value={password} type="password" placeholder='Password' name='password' />
            <button onClick={getLogs}>View Log</button>
        </div>
        <Spinner loading={loading} />
        {logs.length !== 0 && <table>
            <tr>
                <th>Date</th>
                <th>Action</th>
                <th>Data</th>
            </tr>
            {logs.map((log, index) => <tr>
                <td>{log.date}</td>
                <td>{log.action}</td>
                <td>{log.data}</td>
            </tr>)}
        </table>}
    </div>
}